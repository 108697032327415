<template>
  <b-breadcrumb>
    <b-breadcrumb-item to="/">Inicio</b-breadcrumb-item>

    <b-breadcrumb-item :to="item.path" :key="index" v-for="(item, index) in items">
      {{ item.text }}
    </b-breadcrumb-item>
    <span class="ml-2 text-bg-60"> / </span>
    <span class="text-info-3 ml-2 active-breadcrumb">
      {{ activeTitle }}
    </span>
  </b-breadcrumb>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem } from 'bootstrap-vue'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    activeTitle: {
      type: String,
      default: ''
    }
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem
  },
  methods: {}
}
</script>
