<template>
  <div
    style="cursor: pointer"
    :class="{ 'bg-white py-2 px-3 rounded': sizeLarge }"
  >
    <b-row align-h="start" align-v="center" @click="emitClick">
      <b-col cols="12" sm="12" class="mb-16 mb-sm-0 p-2 pl-3">
        <b-row :wrap="false" align-v="center">
          <b-col class="hp-flex-none w-auto pr-0">
            <div
              class="hp-cursor-pointer border hp-border-color-dark-80 mr-2"
              style="border-radius: 15px"
            >
              <div
                :class="`overflow-hidden m-4 d-flex hp-bg-warning-4`"
                style="
                  min-width: 64px;
                  width: 64px;
                  height: 64px;
                  border-radius: 15px;
                "
              >
                <img v-if="img" :src="img" :alt="title" height="100%" />
              </div>
            </div>
          </b-col>
          <b-col class="hp-flex-none w-auto pl-2">
            <span
              class="d-block mb-0"
              :class="{ h6: !sizeLarge, h4: sizeLarge }"
              >{{ title }}</span
            >
            <span
              class="d-block my-1"
              :class="{ 'hp-p1-body': !sizeLarge, h6: sizeLarge }"
              >{{ subtitle }}</span
            >

            <span
              class="d-block my-1"
              v-if="desc"
              :class="{ 'hp-p1-body': !sizeLarge, h6: sizeLarge }"
              >{{ desc }}</span
            >

            <span
              class="d-block my-1"
              v-if="code"
              :class="{ 'hp-p1-body': !sizeLarge, h6: sizeLarge }"
              >{{ code }}</span
            >
            <small
              v-if="number"
              :class="{ 'hp-p1-body': !sizeLarge, h6: sizeLarge }"
            >
              Código: {{ number }}
            </small>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String },
    subtitle: { type: String },
    desc: { default: null },
    img: { required: false },
    number: { type: String },
    code: { type: String },
    sizeLarge: { type: Boolean, default: false },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>
