<template>
  <b-row>
    <b-col cols="12" class="mb-32">
      <b-row align-h="between" class="mt-n32">
        <b-col class="hp-flex-none w-auto mt-32">
          <breadcrumb activeTitle="Mis Puntos de Instalación" />
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" class="mb-32">
      <h2 class="mb-0">Mis Puntos de Instalación</h2>
      <h5 class="mb-0 text-black-80 hp-text-color-dark-30">Listado</h5>
    </b-col>

    <b-col cols="12" class="mb-32 mx-3 px-5">
      <b-row>
        <b-col cols="12" xl="4" md="4" sm="12">
          <b-form-input
            v-model="filters.name"
            type="text"
            @input="getPlacesData()"
            placeholder="Buscar por nombre"
          ></b-form-input>
        </b-col>
        <b-col cols="12" xl="4" md="4" sm="12">
          <b-form-input
            v-model="filters.code"
            type="text"
            @input="getPlacesData()"
            placeholder="Buscar por código"
          ></b-form-input>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12">
      <b-row
        align-content="center"
        align-h="center"
        align-v="center"
        v-if="wrappedPlaces.length"
      >
        <b-col
          cols="12"
          md="5"
          sm="12"
          class="hp-bg-black-0 hp-bg-dark-100 border hp-border-color-black-10 hp-border-color-dark-80 p-12 mx-5 mb-16"
          style="border-radius: 24px"
          v-for="(item, index) in wrappedPlaces"
          :key="index"
        >
          <large-card
            @click="goToShow(item)"
            :title="item.name"
            :subtitle="item.project.name"
            :img="item.image"
            :number="item.code"
          ></large-card>
        </b-col>
      </b-row>
      <h5 v-else class="text-center mt-16">
        Sin puntos de instalación actualmente
      </h5>
    </b-col>
  </b-row>
</template>

<script>
import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import LargeCard from "@/view/components/common/cards/LargeCard.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      filters: {
        name: "",
        code: "",
      },
      debounce: null,
      debounceTime: 400,
      currentUserId: null,
    };
  },
  components: {
    Breadcrumb,
    PageTitle,
    LargeCard,
  },
  methods: {
    ...mapActions({
      getPlaces: "place/getPlaces",
    }),
    async getPlacesData() {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function () {
        await self.getPlaces({
          user_id: self.currentUserId,
          name: self.filters.name ?? undefined,
          code: self.filters.code ?? undefined,
        });
        self.loading = false;
      }, self.debounceTime);
    },
    goToShow(item) {
      this.$router.push({
        name: "show-place-information",
        params: {
          id: item.id,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      places: "place/myPlaces",
      currentUser: "auth/currentUser",
    }),
    wrappedPlaces() {
      return this.places.map((place) => {
        return {
          ...place,
          image: place.image
            ? place.image
            : require("@/assets/img/app/places/place-icon.png"),
        };
      });
    },
  },
  watch: {
    currentUser: {
      immediate: true,
      handler: function (user) {
        if (user) {
          this.currentUserId = user.id;
          this.getPlaces({ user_id: user.id });
        }
      },
    },
  },
};
</script>
